import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";
import ResultCard from "./ResultCard";
import { Percentage, Speed, SpeedContainer, SubSpeed, SubtitleDiv, SubtitleRightDiv } from "./ResultElements";
import { Models } from "@visonum/network-quality-sdk";
import { getSpeedKind } from "./resultUtils";
import ProgressBar from "./ProgressBar";

const RootDiv = styled.div([
    {
        display: "flex",
        justifyContent: "space-between",
        flexGrow: 10,
    }
]);

const LeftPartDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        minWidth: r(580),
    }
]));

const RouterName = styled.span(({ theme }) => ([
    theme.gigatv.typography.bold,
    {
    }
]));

const RightPartDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.background.deviceDownload,
    {
        display: "flex",
        flexDirection: "column",
        minWidth: r(380),
        borderRadius: 6,
        padding: r(20),
    }
]));

const FeaturesContainer = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.primary,
    theme.gigatv.typography.fontSize20,
    theme.gigatv.typography.bold,
    {
        marginTop: "auto",
        display: "flex",
        columnGap: r(8),
    }
]));

const FeatureDiv = styled.div([
    {
        display: "flex",
        alignItems: "center",
        columnGap: r(10),
    }
]);

const FeatureIconDiv = styled.div([
    {
        width: r(20),
        height: r(20),
    }
]);

const LanLimitationTextDiv = styled.div(({ theme }) => ([
    theme.gigatv.colors.foreground.thirthy,
    theme.gigatv.typography.fontSize22,
    {
        marginTop: r(8),
    }
]));


interface DownloadCardProps {
    deviceSpeed: number;
    downloadModemSpeed: number | null;
    bookedDownloadSpeedMax: number | null;
    bookedDownloadSpeedAvg: number | null;
    bookedDownloadSpeedMin: number | null;
    deviceName: string;
    routerName: string | null;
    connectionType: Models.SnmpResultModelConnectionTypeEnum | null;
    downloadIsOk: boolean;
}

const DownloadCard: React.FC<DownloadCardProps> = ({ deviceSpeed, downloadModemSpeed, bookedDownloadSpeedMax, bookedDownloadSpeedAvg, bookedDownloadSpeedMin, deviceName, routerName, connectionType, downloadIsOk }) => {

    const connectionToStr = () => {
        switch (connectionType) {
            case null: return "";
            case Models.SnmpResultModelConnectionTypeEnum.Lan: return "(im Lan)";
            case Models.SnmpResultModelConnectionTypeEnum.Wlan24: return "(im WLAN)";
            case Models.SnmpResultModelConnectionTypeEnum.Wlan5: return "(im WLAN5)";
        }
    }

    const progress = (currentSpeedMbps: number) => {
        if (bookedDownloadSpeedMax === null ||
            bookedDownloadSpeedAvg === null ||
            bookedDownloadSpeedMin === null) {
            return null;
        }

        const max = bookedDownloadSpeedMax * 1e-6;
        const avg = bookedDownloadSpeedAvg * 1e-6;
        const min = bookedDownloadSpeedMin * 1e-6;
        const current = currentSpeedMbps * 1e-6;

        const speedKind = getSpeedKind(current, avg, min);

        return (
            <ProgressBar speedKind={speedKind} min={min} avg={avg} max={max} current={current} />
        );
    }

    const featureIcon = (allowed: boolean) => {
        return allowed ? (
            <svg width="100%" height="100%" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11" cy="10.5" r="10" fill="#008A00" />
                <path d="M20.9071 4.4666C21.1023 4.27133 21.1023 3.95474 20.907 3.75949C20.7118 3.56424 20.3952 3.56425 20.1999 3.75952L20.9071 4.4666ZM9.94971 14.7178L9.59598 15.0711L9.94955 15.4251L10.3033 15.0713L9.94971 14.7178ZM5.59246 9.64889C5.3973 9.45353 5.08071 9.45338 4.88536 9.64855C4.69 9.84372 4.68985 10.1603 4.88502 10.3557L5.59246 9.64889ZM21.122 7.71571C21.0488 7.44947 20.7735 7.29303 20.5073 7.36631C20.2411 7.43958 20.0846 7.71481 20.1579 7.98106L21.122 7.71571ZM15.0601 19.6392L15.2628 20.0962L15.2634 20.096L15.0601 19.6392ZM2.57029 15.875L2.99188 15.6062L2.99176 15.606L2.57029 15.875ZM4.42338 2.96404L4.09479 2.58718L4.09456 2.58738L4.42338 2.96404ZM17.1457 3.25472C17.3563 3.43336 17.6718 3.40747 17.8504 3.19689C18.0291 2.98632 18.0032 2.67079 17.7926 2.49216L17.1457 3.25472ZM20.1999 3.75952L9.59614 14.3642L10.3033 15.0713L20.9071 4.4666L20.1999 3.75952ZM10.3034 14.3644L5.59246 9.64889L4.88502 10.3557L9.59598 15.0711L10.3034 14.3644ZM20.1579 7.98106C21.4058 12.5154 19.1488 17.2729 14.8569 19.1823L15.2634 20.096C20.0063 17.9859 22.5014 12.7277 21.122 7.71571L20.1579 7.98106ZM14.8574 19.1821C10.5617 21.0876 5.51906 19.5694 2.99188 15.6062L2.14871 16.1438C4.94195 20.5243 10.5155 22.202 15.2628 20.0962L14.8574 19.1821ZM2.99176 15.606C0.464623 11.6467 1.2118 6.43149 4.75221 3.3407L4.09456 2.58738C0.181431 6.00356 -0.64446 11.7678 2.14883 16.144L2.99176 15.606ZM4.75197 3.34091C8.29259 0.253854 13.5624 0.214907 17.1457 3.25472L17.7926 2.49216C13.8318 -0.867912 8.00771 -0.824492 4.09479 2.58718L4.75197 3.34091Z" fill="white" />
            </svg>
        ) : (
            <svg width="100%" height="100%" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11" cy="10.5" r="10" fill="#BEBEBE" />
                <path d="M20.9071 4.4666C21.1023 4.27133 21.1023 3.95474 20.907 3.75949C20.7118 3.56424 20.3952 3.56425 20.1999 3.75952L20.9071 4.4666ZM9.94971 14.7178L9.59598 15.0711L9.94955 15.4251L10.3033 15.0713L9.94971 14.7178ZM5.59246 9.64889C5.3973 9.45353 5.08071 9.45338 4.88536 9.64855C4.69 9.84372 4.68985 10.1603 4.88502 10.3557L5.59246 9.64889ZM21.122 7.71571C21.0488 7.44947 20.7735 7.29303 20.5073 7.36631C20.2411 7.43958 20.0846 7.71481 20.1579 7.98106L21.122 7.71571ZM15.0601 19.6392L15.2628 20.0962L15.2634 20.096L15.0601 19.6392ZM2.57029 15.875L2.99188 15.6062L2.99176 15.606L2.57029 15.875ZM4.42338 2.96404L4.09479 2.58718L4.09456 2.58738L4.42338 2.96404ZM17.1457 3.25472C17.3563 3.43336 17.6718 3.40747 17.8504 3.19689C18.0291 2.98632 18.0032 2.67079 17.7926 2.49216L17.1457 3.25472ZM20.1999 3.75952L9.59614 14.3642L10.3033 15.0713L20.9071 4.4666L20.1999 3.75952ZM10.3034 14.3644L5.59246 9.64889L4.88502 10.3557L9.59598 15.0711L10.3034 14.3644ZM20.1579 7.98106C21.4058 12.5154 19.1488 17.2729 14.8569 19.1823L15.2634 20.096C20.0063 17.9859 22.5014 12.7277 21.122 7.71571L20.1579 7.98106ZM14.8574 19.1821C10.5617 21.0876 5.51906 19.5694 2.99188 15.6062L2.14871 16.1438C4.94195 20.5243 10.5155 22.202 15.2628 20.0962L14.8574 19.1821ZM2.99176 15.606C0.464623 11.6467 1.2118 6.43149 4.75221 3.3407L4.09456 2.58738C0.181431 6.00356 -0.64446 11.7678 2.14883 16.144L2.99176 15.606ZM4.75197 3.34091C8.29259 0.253854 13.5624 0.214907 17.1457 3.25472L17.7926 2.49216C13.8318 -0.867912 8.00771 -0.824492 4.09479 2.58718L4.75197 3.34091Z" fill="white" />
            </svg>
        );
    }

    const feature = () => {
        return (
            <FeaturesContainer>
                <FeatureDiv>
                    <FeatureIconDiv>
                        {featureIcon(deviceSpeed > 10e6)}
                    </FeatureIconDiv>
                    <span>Full HD</span>
                </FeatureDiv>
                <FeatureDiv>
                    <FeatureIconDiv>
                        {featureIcon(deviceSpeed > 25e6)}
                    </FeatureIconDiv>
                    <span>Ultra HD (4K)</span>
                </FeatureDiv>
            </FeaturesContainer>
        );
    }

    const lanLimitation = () => {
        if (!downloadIsOk) {
            return (
                <LanLimitationTextDiv>
                    (per LAN max. 100 Mbit/s möglich)
                </LanLimitationTextDiv>
            );
        }

        return null;
    }

    if (downloadModemSpeed === null) {
        return (
            <ResultCard title="Download">
                <RootDiv>
                    <LeftPartDiv>
                        <SpeedContainer>
                            <Speed currentSpeed={deviceSpeed} bookedSpeedMax={bookedDownloadSpeedMax} />
                            <Percentage
                                currentSpeed={deviceSpeed}
                                bookedSpeedMax={bookedDownloadSpeedMax}
                                bookedSpeedAvg={bookedDownloadSpeedAvg}
                                bookedSpeedMin={bookedDownloadSpeedMin} />
                        </SpeedContainer>
                        <SubtitleDiv>
                            Messung an Deinem Gerät: <strong>{deviceName}</strong> {connectionToStr()}
                        </SubtitleDiv>
                        {progress(deviceSpeed)}
                    </LeftPartDiv>

                    <div style={{ height: "100%", background: "transparent", display: "flex", alignItems: "end" }}>
                        {feature()}
                    </div>
                </RootDiv>
            </ResultCard>
        );
    } else {
        return (
            <ResultCard title={"Download"}>
                <RootDiv>
                    <LeftPartDiv>
                        <SpeedContainer>
                            <Speed currentSpeed={downloadModemSpeed} bookedSpeedMax={bookedDownloadSpeedMax} />
                            <Percentage
                                currentSpeed={downloadModemSpeed}
                                bookedSpeedMax={bookedDownloadSpeedMax}
                                bookedSpeedAvg={bookedDownloadSpeedAvg}
                                bookedSpeedMin={bookedDownloadSpeedMin} />
                        </SpeedContainer>
                        {routerName === null ? (
                            <SubtitleDiv>
                                Messung an Deinem Anschluss
                            </SubtitleDiv>
                        ) : (
                            <SubtitleDiv>
                                Messung an Deinem Anschluss: <strong>{routerName}</strong> (am Router)
                            </SubtitleDiv>
                        )}
                        {progress(downloadModemSpeed)}
                    </LeftPartDiv>

                    <RightPartDiv>
                        <SubSpeed currentSpeed={deviceSpeed} bookedSpeedMax={bookedDownloadSpeedMax} />
                        <SubtitleRightDiv>
                            Messung an Deinem Gerät:<br />
                            <strong>GigaTV</strong> {connectionToStr()}
                        </SubtitleRightDiv>
                        {lanLimitation()}
                        {feature()}
                    </RightPartDiv>
                </RootDiv>
            </ResultCard>
        );
    }
}

export default DownloadCard;