import styled from "@emotion/styled";
import * as R from "ramda";
import { ClickableDiv } from "./ClickableDiv";

interface DivProps {
    disabled: boolean;
}

const RootDiv = styled.div<DivProps>(({ disabled }) => ([
    {
        display: "flex",
        columnGap: 8,
        alignItems: "center",
        cursor: disabled ? "default" : "pointer",
        opacity: disabled ? 0.6 : 1,
    }
]));

const BaseDiv = styled.div<DivProps>(({ theme, disabled }) => ([
    disabled ? theme.splus3.background.defaultTransparent : theme.splus3.background.white,
    theme.splus3.shadow.checkBox,
    theme.splus3.border.checkBoxBoolean,
    {
        width: 20,
        height: 20,
        borderRadius: 4,
    }
]));

const UncheckedDiv = styled(BaseDiv)(({ theme }) => ([
]));

const CheckedDiv = styled(BaseDiv)(({ theme }) => ([
]));

interface CheckBoxBooleanProps {
    checked: boolean;
    onClick?: () => void;
    isDialog?: boolean;
}

const CheckBoxBoolean: React.FC<CheckBoxBooleanProps> = ({ checked, onClick, isDialog = false, children }) => {
    const disabled = R.isNil(onClick);

    return (
        <ClickableDiv onClick={onClick} enabled={disabled ? false : (isDialog ? true : "auto")} role="checkbox" ariaChecked={!disabled} ariaLabel="checkbox">
            <RootDiv disabled={disabled}>
                {
                    (() => {
                        switch (checked) {
                            case false: return <UncheckedDiv disabled={disabled} />
                            case true: return (
                                <CheckedDiv disabled={disabled}>
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.2074 5.29279C17.3949 5.48031 17.5002 5.73462 17.5002 5.99979C17.5002 6.26495 17.3949 6.51926 17.2074 6.70679L9.20741 14.7068C9.01988 14.8943 8.76557 14.9996 8.50041 14.9996C8.23524 14.9996 7.98094 14.8943 7.79341 14.7068L3.79341 10.7068C3.61125 10.5182 3.51045 10.2656 3.51273 10.0034C3.51501 9.74119 3.62018 9.49038 3.80559 9.30497C3.991 9.11956 4.24181 9.01439 4.50401 9.01211C4.7662 9.00983 5.0188 9.11063 5.20741 9.29279L8.50041 12.5858L15.7934 5.29279C15.9809 5.10532 16.2352 5 16.5004 5C16.7656 5 17.0199 5.10532 17.2074 5.29279Z" fill="#0D0D0D" />
                                    </svg>
                                </CheckedDiv>
                            )
                        }
                    })()
                }
                {children}
            </RootDiv>
        </ClickableDiv>
    );
}

export default CheckBoxBoolean;