import { createNetworkQuality, NetworkQualitySDK, PrepareResultDE } from "@visonum/network-quality-sdk";
import * as R from "ramda";
import { baseUrl, API_KEY, VARIANT } from "../../config";
import { logger } from "../../helper/logger";
import { getUrlParamValue, getUrlQuery, nullOrValue } from "../../helper/utils";
import { getMeasurementModeFromUrl } from "../getMeasurementModeFromUrl";
import { getPackageName, getPackageVersion } from "../../helper/packageJsonHelper";
import { produce } from "immer";
import sentryObject from "src/features/sentryReports/sentryObject";

function mkDataOverride(url: string | null): ((prepareResult: PrepareResultDE) => PrepareResultDE) {
    const downloadServer = getUrlParamValue(url, "download-server");
    const uploadServer = getUrlParamValue(url, "upload-server");
    const wsPingServer = getUrlParamValue(url, "ws-ping-server");
    const downloadDuration = parseFloat(getUrlParamValue(url, "download-duration") ?? "");
    return (prepareResult: PrepareResultDE) => produce(prepareResult, draft => {
        if (!isNaN(downloadDuration)) {
            draft.config.download.duration = downloadDuration;
        }
        if (!R.isNil(downloadServer)) {
            draft.init.speedtest.servers.downloadServers = {
                dualstack: [downloadServer],
                ipv6: [downloadServer],
            }
        }
        if (!R.isNil(uploadServer)) {
            draft.init.speedtest.servers.uploadServer = {
                dualstack: uploadServer,
                ipv6: uploadServer,
            }
        }
        if (!R.isNil(wsPingServer)) {
            draft.init.speedtest.servers.wsPingServer = {
                dualstack: wsPingServer,
                ipv6: wsPingServer,
            }
        }

        // draft.init.modem = {
        //     provisionedDownloadSpeed: 110_000_000,
        //     bookedDownloadSpeedMax: 100_000_000,
        //     provisionedUploadSpeed: 55_000_000,
        //     bookedUploadSpeedMax: 51_000_000,
        // }

        // draft.init.speedtest.servers.downloadServers = {
        //     dualstack: ["https://speedtest-61.speedtest.vodafone-ip.de/data/download/512M"],
        //     ipv6: ["https://speedtest-61v6.speedtest.vodafone-ip.de/data/download/512M"],
        // }

        // draft.init.speedtest.servers.uploadServer = {
        //     dualstack: "https://speedtest-61.speedtest.vodafone-ip.de/empty.txt",
        //     ipv6: "https://speedtest-61v6.speedtest.vodafone-ip.de/empty.txt",
        // }
    });
}

export const createSdkJs = (url: string | null): NetworkQualitySDK => {
    const clientQueryString = getClientQueryStringFromUrl(url);
    if (!R.isNil(clientQueryString)) {
        logger.info(`Query String: "${clientQueryString}"`);
    }

    const mode = getMeasurementModeFromUrl(url);
    logger.info(`Measurement Mode: "${mode}"`);

    function versionSuffix(): string {
        switch (VARIANT) {
            case "SPLUS":
                return "-splus"
            case "SPLUS3":
                return "-splus3"
            default:
                return ""
        }
    } 

    const sdk = createNetworkQuality(mode, baseUrl, API_KEY, {
        clientBundleId: getPackageName(),
        clientVersion: getPackageVersion() + versionSuffix(),
        clientQueryString,
        logger,
        dataOverride: mkDataOverride(url),
        useRequestId: (VARIANT == "SPLUS3" || VARIANT == "TV"),
        logRetry: (name, tags) => {
            logger.warn(name, tags);
            sentryObject.captureMessage(name, { tags: Object.fromEntries(tags.entries()) });
        }
    });

    return sdk;
}

const getClientQueryStringFromUrl = (url: string | null) => getUrlQuery(url);