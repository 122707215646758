import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HomePageState, MeasurementPageState, PageKey, ResultPageState, ResultTabKey } from "./pageState";
import { getOfferedSurvey, setOfferedSurvery } from "src/helper/storage/localStorage";
import ConsentDialog from "src/pages/splus3/dialogs/ConsentDialog";
import { AppThunk } from "src/store";

interface PageManagerState {
    currentPage: PageKey;
    homeState: HomePageState;
    measurementState: MeasurementPageState;
    resultState: ResultPageState;
}

const initialState: PageManagerState = {
    currentPage: PageKey.Home,
    homeState: {},
    measurementState: {},
    resultState: {
        currentTab: ResultTabKey.CurrentMesasurement,
        showSurveyModal: false,
        offeredSurvey: false,
    },
}

export const initPageManager = (): AppThunk => (dispatch) => {
    const value = getOfferedSurvey();
    dispatch(updateOfferedSurvey(value!!));
};

export const writeOfferedSurvery = (): AppThunk => (dispatch) => {
    setOfferedSurvery(true)
    dispatch(updateOfferedSurvey(true))
};

export const pageManagerSlice = createSlice({
    name: 'pageManager',
    initialState,
    reducers: {
        updatePage: (state, action: PayloadAction<PageKey>) => {
            state.currentPage = action.payload;
        },
        updateResultPageTab: (state, action: PayloadAction<ResultTabKey>) => {
            state.resultState.currentTab = action.payload;

            if ((action.payload !== ResultTabKey.CurrentMesasurement) && !state.resultState.offeredSurvey) {
                state.resultState.showSurveyModal = true;
            }
        },
        updateOfferedSurvey: (state, action: PayloadAction<boolean>) => {
            state.resultState.offeredSurvey = action.payload;
            state.resultState.showSurveyModal = false;
        }
    }
});

export const {
    updatePage,
    updateResultPageTab,
    updateOfferedSurvey,
} = pageManagerSlice.actions;

export default pageManagerSlice;