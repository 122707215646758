import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import { selectLatestRecord } from "../history/selectLatestRecord";
import { getServerHints, serverHintsToClientHintIds } from "./hintFunctions";
import { createNqError, NQHint } from "@visonum/network-quality-sdk";
import { NQRecord } from "../history/types";
import * as R from "ramda";
import { getInputFromNqRecord, getPartialServiceState } from "../partialService/partialServiceUtils";
import { VARIANT } from "../../config";
import getHints3 from "./getHints3";
import getHints from "./getHints";
import { defaultLanguage } from "../../helper/utils";
import { logger } from "../../helper/logger";
import sentryCaptureError from "../sentryReports/sentryCaptureError";

export interface HintsState {
  hintIds: number[],
  currentIndex: number | null;
  isExpanded: boolean | null; //null means collapsed without animation
}

const initialState: HintsState = {
  hintIds: [],
  currentIndex: null,
  isExpanded: false,
}

const getNqHints = async (record: NQRecord | null): Promise<NQHint[]> => {
  if (record === null) {
    return await getServerHints();
  } else if (record.hints === null) {
    return await getServerHints(record.prepareResult.init.speedtest.id);
  } else {
    return record.hints.map(id => ({ id } as NQHint));
  }
}

export const updateNqRecordHints = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const lastRecord = selectLatestRecord(state);
  const nqHints = await getNqHints(lastRecord);
  const hintIds = serverHintsToClientHintIds(nqHints, getPartialServiceState(R.isNil(lastRecord) ? null : getInputFromNqRecord(lastRecord)));
  const existingHintIds = (VARIANT === "SPLUS3" ? getHints3 : getHints)(defaultLanguage).map(x => x.id);
  const result = hintIds.map(id => {
    if (!existingHintIds.includes(id)) {
      const nqError = createNqError("Cl-16", `Tip is not found by Id = ${id}`);
      logger.warn(`Tip #${id} not found.`, nqError);
      sentryCaptureError(nqError);
      return null;
    } else {
      return id;
    }
  }).filter(x => x !== null).map(x => x!);

  dispatch(updateHintIds(result));
}

export const hintsSlice = createSlice({
  name: 'hints',
  initialState,
  reducers: {
    updateHintIds: (_, action: PayloadAction<number[]>) => {
      return {
        hintIds: action.payload,
        currentIndex: action.payload.length > 0 ? 0 : null,
        isExpanded: null,
      }
    },
    updateCurrenIndex: (state, action: PayloadAction<number>) => {
      if (state.currentIndex !== action.payload) {
        state.currentIndex = action.payload;
        state.isExpanded = null;
      }
    },
    updateHintIsExpanded: (state, action: PayloadAction<boolean | null>) => {
      state.isExpanded = action.payload;
    },
  }
});

export const prevIsEnabled = (state: HintsState) => state.currentIndex !== null && state.currentIndex > 0;

export const nextIsEnabled = (state: HintsState) => state.currentIndex !== null && state.currentIndex < (state.hintIds.length - 1);

export const { updateHintIds, updateCurrenIndex, updateHintIsExpanded } = hintsSlice.actions;

export default hintsSlice;