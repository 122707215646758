import styled from "@emotion/styled";
import { r } from "../../../../../theme/gigatv/sizeUtils";
import ResultCard from "./ResultCard";
import { Percentage, Speed, SpeedContainer, SubtitleDiv } from "./ResultElements";
import ProgressBar from "./ProgressBar";
import { getSpeedKind } from "./resultUtils";

const RootDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        width: r(580),
    }
]);

interface UploadCardProps {
    currentSpeed: number;
    bookedUploadSpeedMax: number | null;
    bookedUploadSpeedAvg: number | null;
    bookedUploadSpeedMin: number | null;
}

const UploadCard: React.FC<UploadCardProps> = ({ currentSpeed, bookedUploadSpeedMax, bookedUploadSpeedAvg, bookedUploadSpeedMin }) => {

    const progress = () => {
        if (bookedUploadSpeedMax === null ||
            bookedUploadSpeedAvg === null ||
            bookedUploadSpeedMin === null) {
            return null;
        }

        const max = bookedUploadSpeedMax * 1e-6;
        const avg = bookedUploadSpeedAvg * 1e-6;
        const min = bookedUploadSpeedMin * 1e-6;
        const current = currentSpeed * 1e-6;

        const speedKind = getSpeedKind(current, avg, min);

        return (
            <ProgressBar speedKind={speedKind} min={min} avg={avg} max={max} current={current} />
        );
    }

    return (
        <ResultCard title={"Upload"}>
            <RootDiv>
                <SpeedContainer>
                    <Speed currentSpeed={currentSpeed} bookedSpeedMax={bookedUploadSpeedMax} />
                    <Percentage
                        currentSpeed={currentSpeed}
                        bookedSpeedMax={bookedUploadSpeedMax}
                        bookedSpeedAvg={bookedUploadSpeedAvg}
                        bookedSpeedMin={bookedUploadSpeedMin}
                    />
                </SpeedContainer>
                <SubtitleDiv>
                    Messung an Deinem Anschluss
                </SubtitleDiv>
                {progress()}
            </RootDiv>
        </ResultCard>
    );
}

export default UploadCard;